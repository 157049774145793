import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Facebook, Instagram, YouTube} from "@mui/icons-material";
import * as React from "react";

const Footer = () => {
    return(

        <Box
            sx={{display:'flex',flexDirection:'row',py:5, backgroundColor:'#2A4657'}}
        >
            <Box sx={{flexGrow:1}}>
                <Typography color={'white'}>
                    © 2023 Shree Shivay Infrastructure. All Rights Reserved.
                </Typography>
            </Box>
            <Box sx={{flexGrow:1,color:'white',flexDirection:{md:'row',xs:'column'}}}>
                <Facebook sx={{fontSize:40}} />
                <Instagram sx={{fontSize:40}} />
                <YouTube sx={{fontSize:40}} />
            </Box>
        </Box>
    )
}

export default Footer;