import React from 'react';
import {Box,Typography} from "@mui/material/";
import RectangularCardsData from "../constant/RectangularCardsData"
import CircularCard from "../components/CircularCard";
import RectangleCard from "../components/RectangleCard";
import CTOLargeCard from "../components/CTOLargeCard"
import cardImage1 from "../assets/image1.png";
import cardImage2 from "../assets/image2.png";
import cardImage3 from "../assets/image3.png";

function Home() {
    return (
        <div>
            {/* Circular Cards Section */}
            <Box sx={{
                background: { xs: '#415A6A', md: 'inherit' },
                display: 'flex',
                flexDirection: { md: 'row', xs: 'column' }, // Align cards horizontally // Space between the cards
                p: 4,
                gap: { md: 4 },
                justifyContent: 'center', // Center cards horizontally
                alignItems: 'center',
                position: { sm: 'absolute', xs: 'relative' }, // Position it above the next section
                top: { md: '100%' }, // Position the cards 50% above the next section
                left: { md: '50%' }, // Align it in the center of the screen
                transform: { md: 'translate(-50%, -50%)' }, // Center horizontally and vertically
                zIndex: 1, // Ensure this content appears above other content
            }} >
                <Box sx={{ display: "flex", flexDirection: { xs: 'row' }, gap: { md: 4, xs: 2 } }}>
                    <CircularCard
                        imageSrc={cardImage1}
                        text="Residental"
                    />
                    <CircularCard
                        imageSrc={cardImage2}
                        text="Commercial"
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'row' } }}>
                    <CircularCard
                        imageSrc={cardImage3}
                        text="For Hot Sale"
                    />
                </Box>
            </Box>

            {/* Typography */}
            <Box sx={{
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                gap: 2,
                p: 4,
                mt: { xs: 5, md: 40 }
            }}>
                <Typography sx={{ typography: { md: "h2", xs: "h4" }, fontWeight: 'bold' }}>
                    Explore Top Property and Buildings
                </Typography>
                <Typography variant="subtitle1">
                    Discover the Magic of Property with Shree Shivay Infrastructure Property Your Gateway to Exceptional
                    Real Estate Opportunities
                </Typography>
                <Typography variant="subtitle1">
                    Transforming properties into dreams Shree Shivay Infrastructure Property, where your real estate
                    aspirations find their enchanting reality.
                </Typography>
            </Box>

            {/* Rectangular Card */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                overflowX: 'auto', // Enables horizontal scrolling
                gap: 2, // Space between cards
                p: 2, // Padding around the container
                mx: '10%',
                "&::-webkit-scrollbar": {
                    height: 8, // Scrollbar height
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "white",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                },
            }}>
                {RectangularCardsData.map((item) => (
                    <RectangleCard
                        imageSrc={item.image}
                        title={item.title}
                        tag={item.tag}
                    />
                ))}
            </Box>

            {/* CTO */}
            <Box>
                <CTOLargeCard />
            </Box>

            {/* Typography */}
            <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', gap: 2, p: 4 }}>
                <Typography sx={{ typography: { md: "h2", xs: "h4" }, fontWeight: 'bold' }}>
                    Our Luxury Project
                </Typography>
                <Typography variant="subtitle1">
                    Residential Developments, Mixed-Use Developments, Industrial Facilities, Infrastructure Development,
                    Real Estate Investment and Management, Green Building Initiatives, Urban Renewal and Redevelopment.
                </Typography>
            </Box>
        </div>
    );
}

export default Home;
