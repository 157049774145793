import headerBackgroundSM from "../assets/img_1.png";
import headerBackground from "../assets/img.png";
import Box from "@mui/material/Box";
import Header from "./Header";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as React from "react";
import {useEffect, useState} from "react";

const Base = () => {
    const [headerBg, setHeaderBg] = useState('transparent');

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY;
            if (scrollY > 50) {
                setHeaderBg('white'); // Change to any color you like
            } else {
                setHeaderBg('transparent');
            }
        };


        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <Box
            sx={{
                position: 'relative', // Ensures overlay positioning works
                backgroundImage: {
                    xs: `url(${headerBackgroundSM})`,
                    sm: `url(${headerBackgroundSM})`,
                    md: `url(${headerBackground})`,
                },
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: {md: '100vh', xs: "60vh"}, // Full viewport height for background image
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {/* Header with background image occupying 20% of the top */}
            <Box sx={{
                height: '20%',
                display: 'flex',
                alignItems: 'center',
                position: 'relative'
            }}>
                <Header value={headerBg}/>
            </Box>

            {/* Content section overlayed on the remaining 80% of the background image */}
            <Box
                component="main"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 4,
                }}
            >
                <Toolbar/>
                <Box sx={{display: 'flex', flexDirection: "column", alignItems: 'center', gap: 2}}>
                    <Typography sx={{typography: {md: "h2", xs: "h4"}, fontWeight: 'bold'}}>
                        Find Your Dream House In Indore
                    </Typography>
                    <Typography variant="subtitle1">
                        We Offer Properties at Reasonable Rates in Prime Locations.
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#00B5EF",
                            width: 'fit-content',  // Automatically sizes to fit content
                            paddingX: 2  // Adds horizontal padding
                        }}
                    >
                        Enquire Now
                    </Button>
                </Box>

            </Box>
        </Box>
    )
}

export default Base;